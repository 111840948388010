a {
  padding: 0.5rem;
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #aaf;
}

a:visited {
  color: #fff;
}

a.active {
  color: #aaf;
}